import $pac from '~/icons/$pac.svg?react'
import I0xbtc from '~/icons/0xbtc.svg?react'
import I1inch from '~/icons/1inch.svg?react'
import I2give from '~/icons/2give.svg?react'
import Aave from '~/icons/aave.svg?react'
import Abt from '~/icons/abt.svg?react'
import Act from '~/icons/act.svg?react'
import Actn from '~/icons/actn.svg?react'
import Ada from '~/icons/ada.svg?react'
import Add from '~/icons/add.svg?react'
import Adx from '~/icons/adx.svg?react'
import Ae from '~/icons/ae.svg?react'
import Aeon from '~/icons/aeon.svg?react'
import Aeur from '~/icons/aeur.svg?react'
import Agi from '~/icons/agi.svg?react'
import Agrs from '~/icons/agrs.svg?react'
import Aion from '~/icons/aion.svg?react'
import Algo from '~/icons/algo.svg?react'
import Amb from '~/icons/amb.svg?react'
import Amp from '~/icons/amp.svg?react'
import Ampl from '~/icons/ampl.svg?react'
import Ankr from '~/icons/ankr.svg?react'
import Ant from '~/icons/ant.svg?react'
import Ape from '~/icons/ape.svg?react'
import Apex from '~/icons/apex.svg?react'
import Appc from '~/icons/appc.svg?react'
import Ardr from '~/icons/ardr.svg?react'
import Arg from '~/icons/arg.svg?react'
import Ark from '~/icons/ark.svg?react'
import Arn from '~/icons/arn.svg?react'
import Arnx from '~/icons/arnx.svg?react'
import Ary from '~/icons/ary.svg?react'
import Ast from '~/icons/ast.svg?react'
import Atlas from '~/icons/atlas.svg?react'
import Atm from '~/icons/atm.svg?react'
import Atom from '~/icons/atom.svg?react'
import Audr from '~/icons/audr.svg?react'
import Aury from '~/icons/aury.svg?react'
import Auto from '~/icons/auto.svg?react'
import Avax from '~/icons/avax.svg?react'
import Aywa from '~/icons/aywa.svg?react'
import Bab from '~/icons/bab.svg?react'
import Bal from '~/icons/bal.svg?react'
import Band from '~/icons/band.svg?react'
import Bat from '~/icons/bat.svg?react'
import Bay from '~/icons/bay.svg?react'
import Bcbc from '~/icons/bcbc.svg?react'
import Bcc from '~/icons/bcc.svg?react'
import Bcd from '~/icons/bcd.svg?react'
import Bch from '~/icons/bch.svg?react'
import Bcio from '~/icons/bcio.svg?react'
import Bcn from '~/icons/bcn.svg?react'
import Bco from '~/icons/bco.svg?react'
import Bcpt from '~/icons/bcpt.svg?react'
import Bdl from '~/icons/bdl.svg?react'
import Beam from '~/icons/beam.svg?react'
import Bela from '~/icons/bela.svg?react'
import Bix from '~/icons/bix.svg?react'
import Blcn from '~/icons/blcn.svg?react'
import Blk from '~/icons/blk.svg?react'
import Block from '~/icons/block.svg?react'
import Blz from '~/icons/blz.svg?react'
import Bnb from '~/icons/bnb.svg?react'
import Bnt from '~/icons/bnt.svg?react'
import Bnty from '~/icons/bnty.svg?react'
import Booty from '~/icons/booty.svg?react'
import Bos from '~/icons/bos.svg?react'
import Bpt from '~/icons/bpt.svg?react'
import Bq from '~/icons/bq.svg?react'
import Brd from '~/icons/brd.svg?react'
import Bsd from '~/icons/bsd.svg?react'
import Bsv from '~/icons/bsv.svg?react'
import Btc from '~/icons/btc.svg?react'
import Btcd from '~/icons/btcd.svg?react'
import Btch from '~/icons/btch.svg?react'
import Btcp from '~/icons/btcp.svg?react'
import Btcz from '~/icons/btcz.svg?react'
import Btdx from '~/icons/btdx.svg?react'
import Btg from '~/icons/btg.svg?react'
import Btm from '~/icons/btm.svg?react'
import Bts from '~/icons/bts.svg?react'
import Btt from '~/icons/btt.svg?react'
import Btx from '~/icons/btx.svg?react'
import Burst from '~/icons/burst.svg?react'
import Bze from '~/icons/bze.svg?react'
import Call from '~/icons/call.svg?react'
import Cc from '~/icons/cc.svg?react'
import Cdn from '~/icons/cdn.svg?react'
import Cdt from '~/icons/cdt.svg?react'
import Cenz from '~/icons/cenz.svg?react'
import Chain from '~/icons/chain.svg?react'
import Chat from '~/icons/chat.svg?react'
import Chips from '~/icons/chips.svg?react'
import Chsb from '~/icons/chsb.svg?react'
import Chz from '~/icons/chz.svg?react'
import Cix from '~/icons/cix.svg?react'
import Clam from '~/icons/clam.svg?react'
import Cloak from '~/icons/cloak.svg?react'
import Cmm from '~/icons/cmm.svg?react'
import Cmt from '~/icons/cmt.svg?react'
import Cnd from '~/icons/cnd.svg?react'
import Cnx from '~/icons/cnx.svg?react'
import Cny from '~/icons/cny.svg?react'
import Cob from '~/icons/cob.svg?react'
import Colx from '~/icons/colx.svg?react'
import Comp from '~/icons/comp.svg?react'
import Coqui from '~/icons/coqui.svg?react'
import Cred from '~/icons/cred.svg?react'
import Crpt from '~/icons/crpt.svg?react'
import Crv from '~/icons/crv.svg?react'
import Crw from '~/icons/crw.svg?react'
import Crypto from '~/icons/crypto.svg?react'
import Cs from '~/icons/cs.svg?react'
import Ctr from '~/icons/ctr.svg?react'
import Ctxc from '~/icons/ctxc.svg?react'
import Cvc from '~/icons/cvc.svg?react'
import D from '~/icons/d.svg?react'
import Dai from '~/icons/dai.svg?react'
import Dash from '~/icons/dash.svg?react'
import Dat from '~/icons/dat.svg?react'
import Data from '~/icons/data.svg?react'
import Dbc from '~/icons/dbc.svg?react'
import Dcn from '~/icons/dcn.svg?react'
import Dcr from '~/icons/dcr.svg?react'
import Deez from '~/icons/deez.svg?react'
import Dent from '~/icons/dent.svg?react'
import Dew from '~/icons/dew.svg?react'
import Dgb from '~/icons/dgb.svg?react'
import Dgd from '~/icons/dgd.svg?react'
import Dlt from '~/icons/dlt.svg?react'
import Dnt from '~/icons/dnt.svg?react'
import Dock from '~/icons/dock.svg?react'
import Doge from '~/icons/doge.svg?react'
import Dot from '~/icons/dot.svg?react'
import Drgn from '~/icons/drgn.svg?react'
import Drop from '~/icons/drop.svg?react'
import Dta from '~/icons/dta.svg?react'
import Dth from '~/icons/dth.svg?react'
import Dtr from '~/icons/dtr.svg?react'
import Ebst from '~/icons/ebst.svg?react'
import Eca from '~/icons/eca.svg?react'
import Edg from '~/icons/edg.svg?react'
import Edo from '~/icons/edo.svg?react'
import Edoge from '~/icons/edoge.svg?react'
import Ela from '~/icons/ela.svg?react'
import Elec from '~/icons/elec.svg?react'
import Elf from '~/icons/elf.svg?react'
import Elix from '~/icons/elix.svg?react'
import Ella from '~/icons/ella.svg?react'
import Emb from '~/icons/emb.svg?react'
import Emc from '~/icons/emc.svg?react'
import Emc2 from '~/icons/emc2.svg?react'
import Eng from '~/icons/eng.svg?react'
import Enj from '~/icons/enj.svg?react'
import Entrp from '~/icons/entrp.svg?react'
import Eon from '~/icons/eon.svg?react'
import Eop from '~/icons/eop.svg?react'
import Eos from '~/icons/eos.svg?react'
import Eqli from '~/icons/eqli.svg?react'
import Equa from '~/icons/equa.svg?react'
import Etc from '~/icons/etc.svg?react'
import Eth from '~/icons/eth.svg?react'
import Ethos from '~/icons/ethos.svg?react'
import Etn from '~/icons/etn.svg?react'
import Etp from '~/icons/etp.svg?react'
import Eur from '~/icons/eur.svg?react'
import Evx from '~/icons/evx.svg?react'
import Exmo from '~/icons/exmo.svg?react'
import Exp from '~/icons/exp.svg?react'
import Fair from '~/icons/fair.svg?react'
import Fct from '~/icons/fct.svg?react'
import Fida from '~/icons/fida.svg?react'
import Fil from '~/icons/fil.svg?react'
import Fjc from '~/icons/fjc.svg?react'
import Fldc from '~/icons/fldc.svg?react'
import Flo from '~/icons/flo.svg?react'
import Flux from '~/icons/flux.svg?react'
import Fort from '~/icons/fort.svg?react'
import Fsn from '~/icons/fsn.svg?react'
import Ftc from '~/icons/ftc.svg?react'
import Fuel from '~/icons/fuel.svg?react'
import Fun from '~/icons/fun.svg?react'
import Game from '~/icons/game.svg?react'
import Gas from '~/icons/gas.svg?react'
import Gbp from '~/icons/gbp.svg?react'
import Gbx from '~/icons/gbx.svg?react'
import Gbyte from '~/icons/gbyte.svg?react'
import Generic from '~/icons/generic.svg?react'
import Gin from '~/icons/gin.svg?react'
import Glxt from '~/icons/glxt.svg?react'
import Gmr from '~/icons/gmr.svg?react'
import Gmt from '~/icons/gmt.svg?react'
import Gno from '~/icons/gno.svg?react'
import Gnt from '~/icons/gnt.svg?react'
import Gold from '~/icons/gold.svg?react'
import Grc from '~/icons/grc.svg?react'
import Grin from '~/icons/grin.svg?react'
import Grs from '~/icons/grs.svg?react'
import Grt from '~/icons/grt.svg?react'
import Gsc from '~/icons/gsc.svg?react'
import Gto from '~/icons/gto.svg?react'
import Gup from '~/icons/gup.svg?react'
import Gusd from '~/icons/gusd.svg?react'
import Gvt from '~/icons/gvt.svg?react'
import Gxs from '~/icons/gxs.svg?react'
import Gzr from '~/icons/gzr.svg?react'
import Hight from '~/icons/hight.svg?react'
import Hns from '~/icons/hns.svg?react'
import Hodl from '~/icons/hodl.svg?react'
import Hot from '~/icons/hot.svg?react'
import Hpb from '~/icons/hpb.svg?react'
import Hsr from '~/icons/hsr.svg?react'
import Ht from '~/icons/ht.svg?react'
import Html from '~/icons/html.svg?react'
import Huc from '~/icons/huc.svg?react'
import Husd from '~/icons/husd.svg?react'
import Hush from '~/icons/hush.svg?react'
import Icn from '~/icons/icn.svg?react'
import Icp from '~/icons/icp.svg?react'
import Icx from '~/icons/icx.svg?react'
import Ignis from '~/icons/ignis.svg?react'
import Ilk from '~/icons/ilk.svg?react'
import Ink from '~/icons/ink.svg?react'
import Ins from '~/icons/ins.svg?react'
import Ion from '~/icons/ion.svg?react'
import Iop from '~/icons/iop.svg?react'
import Iost from '~/icons/iost.svg?react'
import Iotx from '~/icons/iotx.svg?react'
import Iq from '~/icons/iq.svg?react'
import Itc from '~/icons/itc.svg?react'
import Jnt from '~/icons/jnt.svg?react'
import Jpy from '~/icons/jpy.svg?react'
import Kcs from '~/icons/kcs.svg?react'
import Kin from '~/icons/kin.svg?react'
import Klown from '~/icons/klown.svg?react'
import Kmd from '~/icons/kmd.svg?react'
import Knc from '~/icons/knc.svg?react'
import Krb from '~/icons/krb.svg?react'
import Ksm from '~/icons/ksm.svg?react'
import Lbc from '~/icons/lbc.svg?react'
import Lend from '~/icons/lend.svg?react'
import Leo from '~/icons/leo.svg?react'
import Link from '~/icons/link.svg?react'
import Lkk from '~/icons/lkk.svg?react'
import Loom from '~/icons/loom.svg?react'
import Lpt from '~/icons/lpt.svg?react'
import Lrc from '~/icons/lrc.svg?react'
import Lsk from '~/icons/lsk.svg?react'
import Ltc from '~/icons/ltc.svg?react'
import Lun from '~/icons/lun.svg?react'
import Maid from '~/icons/maid.svg?react'
import Mana from '~/icons/mana.svg?react'
import Matic from '~/icons/matic.svg?react'
import Max from '~/icons/max.svg?react'
import Mcap from '~/icons/mcap.svg?react'
import Mco from '~/icons/mco.svg?react'
import Mda from '~/icons/mda.svg?react'
import Mds from '~/icons/mds.svg?react'
import Med from '~/icons/med.svg?react'
import Meetone from '~/icons/meetone.svg?react'
import Mft from '~/icons/mft.svg?react'
import Miota from '~/icons/miota.svg?react'
import Mith from '~/icons/mith.svg?react'
import Mkr from '~/icons/mkr.svg?react'
import Mln from '~/icons/mln.svg?react'
import Mnx from '~/icons/mnx.svg?react'
import Mnz from '~/icons/mnz.svg?react'
import Moac from '~/icons/moac.svg?react'
import Mod from '~/icons/mod.svg?react'
import Mona from '~/icons/mona.svg?react'
import Msr from '~/icons/msr.svg?react'
import Mth from '~/icons/mth.svg?react'
import Mtl from '~/icons/mtl.svg?react'
import Music from '~/icons/music.svg?react'
import Mzc from '~/icons/mzc.svg?react'
import Nano from '~/icons/nano.svg?react'
import Nas from '~/icons/nas.svg?react'
import Nav from '~/icons/nav.svg?react'
import Ncash from '~/icons/ncash.svg?react'
import Ndz from '~/icons/ndz.svg?react'
import Nebl from '~/icons/nebl.svg?react'
import Neo from '~/icons/neo.svg?react'
import Neos from '~/icons/neos.svg?react'
import Neu from '~/icons/neu.svg?react'
import Nexo from '~/icons/nexo.svg?react'
import Ngc from '~/icons/ngc.svg?react'
import Nio from '~/icons/nio.svg?react'
import Nkn from '~/icons/nkn.svg?react'
import Nlc2 from '~/icons/nlc2.svg?react'
import Nlg from '~/icons/nlg.svg?react'
import Nmc from '~/icons/nmc.svg?react'
import Nmr from '~/icons/nmr.svg?react'
import Npxs from '~/icons/npxs.svg?react'
import Ntbc from '~/icons/ntbc.svg?react'
import Nuls from '~/icons/nuls.svg?react'
import Nxs from '~/icons/nxs.svg?react'
import Nxt from '~/icons/nxt.svg?react'
import Oax from '~/icons/oax.svg?react'
import Ok from '~/icons/ok.svg?react'
import Omg from '~/icons/omg.svg?react'
import Omni from '~/icons/omni.svg?react'
import One from '~/icons/one.svg?react'
import Ong from '~/icons/ong.svg?react'
import Ont from '~/icons/ont.svg?react'
import Oot from '~/icons/oot.svg?react'
import Ost from '~/icons/ost.svg?react'
import Ox from '~/icons/ox.svg?react'
import Oxt from '~/icons/oxt.svg?react'
import Oxy from '~/icons/oxy.svg?react'
import Part from '~/icons/part.svg?react'
import Pasc from '~/icons/pasc.svg?react'
import Pasl from '~/icons/pasl.svg?react'
import Pax from '~/icons/pax.svg?react'
import Paxg from '~/icons/paxg.svg?react'
import Pay from '~/icons/pay.svg?react'
import Payx from '~/icons/payx.svg?react'
import Pink from '~/icons/pink.svg?react'
import Pirl from '~/icons/pirl.svg?react'
import Pivx from '~/icons/pivx.svg?react'
import Plr from '~/icons/plr.svg?react'
import Poa from '~/icons/poa.svg?react'
import Poe from '~/icons/poe.svg?react'
import Polis from '~/icons/polis.svg?react'
import Poly from '~/icons/poly.svg?react'
import Pot from '~/icons/pot.svg?react'
import Powr from '~/icons/powr.svg?react'
import Ppc from '~/icons/ppc.svg?react'
import Ppp from '~/icons/ppp.svg?react'
import Ppt from '~/icons/ppt.svg?react'
import Pre from '~/icons/pre.svg?react'
import Prl from '~/icons/prl.svg?react'
import Pungo from '~/icons/pungo.svg?react'
import Pura from '~/icons/pura.svg?react'
import Qash from '~/icons/qash.svg?react'
import Qiwi from '~/icons/qiwi.svg?react'
import Qlc from '~/icons/qlc.svg?react'
import Qnt from '~/icons/qnt.svg?react'
import Qrl from '~/icons/qrl.svg?react'
import Qsp from '~/icons/qsp.svg?react'
import Qtum from '~/icons/qtum.svg?react'
import R from '~/icons/r.svg?react'
import Rads from '~/icons/rads.svg?react'
import Rap from '~/icons/rap.svg?react'
import Ray from '~/icons/ray.svg?react'
import Rcn from '~/icons/rcn.svg?react'
import Rdd from '~/icons/rdd.svg?react'
import Rdn from '~/icons/rdn.svg?react'
import Ren from '~/icons/ren.svg?react'
import Rep from '~/icons/rep.svg?react'
import Repv2 from '~/icons/repv2.svg?react'
import Req from '~/icons/req.svg?react'
import Rhoc from '~/icons/rhoc.svg?react'
import Ric from '~/icons/ric.svg?react'
import Rise from '~/icons/rise.svg?react'
import Rlc from '~/icons/rlc.svg?react'
import Rpx from '~/icons/rpx.svg?react'
import Rub from '~/icons/rub.svg?react'
import Rvn from '~/icons/rvn.svg?react'
import Ryo from '~/icons/ryo.svg?react'
import Safe from '~/icons/safe.svg?react'
import Safemoon from '~/icons/safemoon.svg?react'
import Sai from '~/icons/sai.svg?react'
import Salt from '~/icons/salt.svg?react'
import San from '~/icons/san.svg?react'
import Sand from '~/icons/sand.svg?react'
import Sbd from '~/icons/sbd.svg?react'
import Sberbank from '~/icons/sberbank.svg?react'
import Sc from '~/icons/sc.svg?react'
import Ser from '~/icons/ser.svg?react'
import Shift from '~/icons/shift.svg?react'
import Sib from '~/icons/sib.svg?react'
import Sin from '~/icons/sin.svg?react'
import Skl from '~/icons/skl.svg?react'
import Sky from '~/icons/sky.svg?react'
import Slr from '~/icons/slr.svg?react'
import Sls from '~/icons/sls.svg?react'
import Smart from '~/icons/smart.svg?react'
import Sngls from '~/icons/sngls.svg?react'
import Snm from '~/icons/snm.svg?react'
import Snt from '~/icons/snt.svg?react'
import Snx from '~/icons/snx.svg?react'
import Soc from '~/icons/soc.svg?react'
import Sol from '~/icons/sol.svg?react'
import Spacehbit from '~/icons/spacehbit.svg?react'
import Spank from '~/icons/spank.svg?react'
import Sphtx from '~/icons/sphtx.svg?react'
import Srn from '~/icons/srn.svg?react'
import Stak from '~/icons/stak.svg?react'
import Start from '~/icons/start.svg?react'
import Steem from '~/icons/steem.svg?react'
import Storj from '~/icons/storj.svg?react'
import Storm from '~/icons/storm.svg?react'
import Stox from '~/icons/stox.svg?react'
import Stq from '~/icons/stq.svg?react'
import Strat from '~/icons/strat.svg?react'
import Stx from '~/icons/stx.svg?react'
import Sub from '~/icons/sub.svg?react'
import Sumo from '~/icons/sumo.svg?react'
import Sushi from '~/icons/sushi.svg?react'
import Sys from '~/icons/sys.svg?react'
import Taas from '~/icons/taas.svg?react'
import Tau from '~/icons/tau.svg?react'
import Tbx from '~/icons/tbx.svg?react'
import Tel from '~/icons/tel.svg?react'
import Ten from '~/icons/ten.svg?react'
import Tern from '~/icons/tern.svg?react'
import Tgch from '~/icons/tgch.svg?react'
import Theta from '~/icons/theta.svg?react'
import Tix from '~/icons/tix.svg?react'
import Tkn from '~/icons/tkn.svg?react'
import Tks from '~/icons/tks.svg?react'
import Tnb from '~/icons/tnb.svg?react'
import Tnc from '~/icons/tnc.svg?react'
import Tnt from '~/icons/tnt.svg?react'
import Tomo from '~/icons/tomo.svg?react'
import Tpay from '~/icons/tpay.svg?react'
import Trig from '~/icons/trig.svg?react'
import Trtl from '~/icons/trtl.svg?react'
import Trx from '~/icons/trx.svg?react'
import Tusd from '~/icons/tusd.svg?react'
import Tzc from '~/icons/tzc.svg?react'
import Ubq from '~/icons/ubq.svg?react'
import Uma from '~/icons/uma.svg?react'
import Uni from '~/icons/uni.svg?react'
import Unity from '~/icons/unity.svg?react'
import Usd from '~/icons/usd.svg?react'
import Usdc from '~/icons/usdc.svg?react'
import Usdt from '~/icons/usdt.svg?react'
import Utk from '~/icons/utk.svg?react'
import Veri from '~/icons/veri.svg?react'
import Vet from '~/icons/vet.svg?react'
import Via from '~/icons/via.svg?react'
import Vib from '~/icons/vib.svg?react'
import Vibe from '~/icons/vibe.svg?react'
import Vivo from '~/icons/vivo.svg?react'
import Vrc from '~/icons/vrc.svg?react'
import Vrsc from '~/icons/vrsc.svg?react'
import Vtc from '~/icons/vtc.svg?react'
import Vtho from '~/icons/vtho.svg?react'
import Wabi from '~/icons/wabi.svg?react'
import Wan from '~/icons/wan.svg?react'
import Waves from '~/icons/waves.svg?react'
import Wax from '~/icons/wax.svg?react'
import Wbtc from '~/icons/wbtc.svg?react'
import Wgr from '~/icons/wgr.svg?react'
import Wicc from '~/icons/wicc.svg?react'
import Wings from '~/icons/wings.svg?react'
import Wpr from '~/icons/wpr.svg?react'
import Wtc from '~/icons/wtc.svg?react'
import X from '~/icons/x.svg?react'
import Xas from '~/icons/xas.svg?react'
import Xbc from '~/icons/xbc.svg?react'
import Xbp from '~/icons/xbp.svg?react'
import Xby from '~/icons/xby.svg?react'
import Xcp from '~/icons/xcp.svg?react'
import Xdn from '~/icons/xdn.svg?react'
import Xem from '~/icons/xem.svg?react'
import Xin from '~/icons/xin.svg?react'
import Xlm from '~/icons/xlm.svg?react'
import Xmcc from '~/icons/xmcc.svg?react'
import Xmg from '~/icons/xmg.svg?react'
import Xmo from '~/icons/xmo.svg?react'
import Xmr from '~/icons/xmr.svg?react'
import Xmy from '~/icons/xmy.svg?react'
import Xp from '~/icons/xp.svg?react'
import Xpa from '~/icons/xpa.svg?react'
import Xpm from '~/icons/xpm.svg?react'
import Xpr from '~/icons/xpr.svg?react'
import Xrp from '~/icons/xrp.svg?react'
import Xsg from '~/icons/xsg.svg?react'
import Xtz from '~/icons/xtz.svg?react'
import Xuc from '~/icons/xuc.svg?react'
import Xvc from '~/icons/xvc.svg?react'
import Xvg from '~/icons/xvg.svg?react'
import Xzc from '~/icons/xzc.svg?react'
import Yfi from '~/icons/yfi.svg?react'
import Yoyow from '~/icons/yoyow.svg?react'
import Zcl from '~/icons/zcl.svg?react'
import Zec from '~/icons/zec.svg?react'
import Zel from '~/icons/zel.svg?react'
import Zen from '~/icons/zen.svg?react'
import Zest from '~/icons/zest.svg?react'
import Zil from '~/icons/zil.svg?react'
import Zilla from '~/icons/zilla.svg?react'
import Zrx from '~/icons/zrx.svg?react'


export const Icons = {
$pac,
I0xbtc,
I1inch,
I2give,
Aave,
Abt,
Act,
Actn,
Ada,
Add,
Adx,
Ae,
Aeon,
Aeur,
Agi,
Agrs,
Aion,
Algo,
Amb,
Amp,
Ampl,
Ankr,
Ant,
Ape,
Apex,
Appc,
Ardr,
Arg,
Ark,
Arn,
Arnx,
Ary,
Ast,
Atlas,
Atm,
Atom,
Audr,
Aury,
Auto,
Avax,
Aywa,
Bab,
Bal,
Band,
Bat,
Bay,
Bcbc,
Bcc,
Bcd,
Bch,
Bcio,
Bcn,
Bco,
Bcpt,
Bdl,
Beam,
Bela,
Bix,
Blcn,
Blk,
Block,
Blz,
Bnb,
Bnt,
Bnty,
Booty,
Bos,
Bpt,
Bq,
Brd,
Bsd,
Bsv,
Btc,
Btcd,
Btch,
Btcp,
Btcz,
Btdx,
Btg,
Btm,
Bts,
Btt,
Btx,
Burst,
Bze,
Call,
Cc,
Cdn,
Cdt,
Cenz,
Chain,
Chat,
Chips,
Chsb,
Chz,
Cix,
Clam,
Cloak,
Cmm,
Cmt,
Cnd,
Cnx,
Cny,
Cob,
Colx,
Comp,
Coqui,
Cred,
Crpt,
Crv,
Crw,
Crypto,
Cs,
Ctr,
Ctxc,
Cvc,
D,
Dai,
Dash,
Dat,
Data,
Dbc,
Dcn,
Dcr,
Deez,
Dent,
Dew,
Dgb,
Dgd,
Dlt,
Dnt,
Dock,
Doge,
Dot,
Drgn,
Drop,
Dta,
Dth,
Dtr,
Ebst,
Eca,
Edg,
Edo,
Edoge,
Ela,
Elec,
Elf,
Elix,
Ella,
Emb,
Emc,
Emc2,
Eng,
Enj,
Entrp,
Eon,
Eop,
Eos,
Eqli,
Equa,
Etc,
Eth,
Ethos,
Etn,
Etp,
Eur,
Evx,
Exmo,
Exp,
Fair,
Fct,
Fida,
Fil,
Fjc,
Fldc,
Flo,
Flux,
Fort,
Fsn,
Ftc,
Fuel,
Fun,
Game,
Gas,
Gbp,
Gbx,
Gbyte,
Generic,
Gin,
Glxt,
Gmr,
Gmt,
Gno,
Gnt,
Gold,
Grc,
Grin,
Grs,
Grt,
Gsc,
Gto,
Gup,
Gusd,
Gvt,
Gxs,
Gzr,
Hight,
Hns,
Hodl,
Hot,
Hpb,
Hsr,
Ht,
Html,
Huc,
Husd,
Hush,
Icn,
Icp,
Icx,
Ignis,
Ilk,
Ink,
Ins,
Ion,
Iop,
Iost,
Iotx,
Iq,
Itc,
Jnt,
Jpy,
Kcs,
Kin,
Klown,
Kmd,
Knc,
Krb,
Ksm,
Lbc,
Lend,
Leo,
Link,
Lkk,
Loom,
Lpt,
Lrc,
Lsk,
Ltc,
Lun,
Maid,
Mana,
Matic,
Max,
Mcap,
Mco,
Mda,
Mds,
Med,
Meetone,
Mft,
Miota,
Mith,
Mkr,
Mln,
Mnx,
Mnz,
Moac,
Mod,
Mona,
Msr,
Mth,
Mtl,
Music,
Mzc,
Nano,
Nas,
Nav,
Ncash,
Ndz,
Nebl,
Neo,
Neos,
Neu,
Nexo,
Ngc,
Nio,
Nkn,
Nlc2,
Nlg,
Nmc,
Nmr,
Npxs,
Ntbc,
Nuls,
Nxs,
Nxt,
Oax,
Ok,
Omg,
Omni,
One,
Ong,
Ont,
Oot,
Ost,
Ox,
Oxt,
Oxy,
Part,
Pasc,
Pasl,
Pax,
Paxg,
Pay,
Payx,
Pink,
Pirl,
Pivx,
Plr,
Poa,
Poe,
Polis,
Poly,
Pot,
Powr,
Ppc,
Ppp,
Ppt,
Pre,
Prl,
Pungo,
Pura,
Qash,
Qiwi,
Qlc,
Qnt,
Qrl,
Qsp,
Qtum,
R,
Rads,
Rap,
Ray,
Rcn,
Rdd,
Rdn,
Ren,
Rep,
Repv2,
Req,
Rhoc,
Ric,
Rise,
Rlc,
Rpx,
Rub,
Rvn,
Ryo,
Safe,
Safemoon,
Sai,
Salt,
San,
Sand,
Sbd,
Sberbank,
Sc,
Ser,
Shift,
Sib,
Sin,
Skl,
Sky,
Slr,
Sls,
Smart,
Sngls,
Snm,
Snt,
Snx,
Soc,
Sol,
Spacehbit,
Spank,
Sphtx,
Srn,
Stak,
Start,
Steem,
Storj,
Storm,
Stox,
Stq,
Strat,
Stx,
Sub,
Sumo,
Sushi,
Sys,
Taas,
Tau,
Tbx,
Tel,
Ten,
Tern,
Tgch,
Theta,
Tix,
Tkn,
Tks,
Tnb,
Tnc,
Tnt,
Tomo,
Tpay,
Trig,
Trtl,
Trx,
Tusd,
Tzc,
Ubq,
Uma,
Uni,
Unity,
Usd,
Usdc,
Usdt,
Utk,
Veri,
Vet,
Via,
Vib,
Vibe,
Vivo,
Vrc,
Vrsc,
Vtc,
Vtho,
Wabi,
Wan,
Waves,
Wax,
Wbtc,
Wgr,
Wicc,
Wings,
Wpr,
Wtc,
X,
Xas,
Xbc,
Xbp,
Xby,
Xcp,
Xdn,
Xem,
Xin,
Xlm,
Xmcc,
Xmg,
Xmo,
Xmr,
Xmy,
Xp,
Xpa,
Xpm,
Xpr,
Xrp,
Xsg,
Xtz,
Xuc,
Xvc,
Xvg,
Xzc,
Yfi,
Yoyow,
Zcl,
Zec,
Zel,
Zen,
Zest,
Zil,
Zilla,
Zrx
}
